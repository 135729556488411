<template>
    <div>
        <iframe src="https://gads2.giikin.com/api/bm/invite" frameborder="0" class="addBm"></iframe>
    </div>
</template>
<script>
export default {
    data(){
        return {}
    }
}
</script>
<style scoped>
.addBm{
     width: 100%;
     height: calc(100vh - 90px);
}
</style>